import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import 'symbol-observable';
import App from './App';
if (env.Sentry.dsn !== null) {
    Sentry.init({
        dsn: env.Sentry.dsn,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: env.NODE_ENV,
        // https://gist.github.com/impressiver/5092952
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            'gtag is not defined',
            // Wordpress specific
            'wpmobileappStartLoading',
            'wpmobileappStopLoading',
            'Non-Error promise rejection captured', // https://github.com/getsentry/sentry-javascript/issues/3440
            'ResizeObserver loop completed with undelivered notifications', // https://yurplan.atlassian.net/browse/YP-9182
            // Meta specific
            "Can't find variable: _AutofillCallbackHandler", // https://bitbucket.org/yurplan/dev-env/pull-requests/1382/overview
        ],
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
        ],
    });
}
var container = document.querySelector('#app');
if (container === null) {
    throw new Error('No container found');
}
var appName = container.getAttribute('data-app-name');
if (appName === null) {
    throw new Error('No application found');
}
var root = createRoot(container);
root.render(React.createElement(App, { appName: appName }));
